.game {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .game-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DC5F00;
    border: 2px solid #834333;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Status styles */
  .status {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Square styles */
  .square {
    width: 100px;
    height: 100px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #E1C16E;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .square:hover {
    background-color: #EADDCA;
  }
  
  /* Board row styles */
  .board-row {
    display: flex;
  }

  
  /* List of moves styles */
  .moves {
    list-style: none;
    padding: 0;
  }
  
  .moves li {
    margin: 5px 0;
  }
  
  /* Button styles for moves */
  .moves button {
    background-color: #EADDCA;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .moves button:hover {
    background-color: #EADDCA;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .game {
      flex-direction: column;
    }
  
    .game-board {
      width: 100%;
      margin: 20px;
    }
  
    .square {
      width: 80px;
      height: 80px;
      font-size: 24px;
    }
  }

