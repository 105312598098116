@font-face {
    font-family: 'LongGame';
    src: url('../../../public/font/TheGlobePersonalUseBold-2ORlw.ttf') format('truetype'); /* Adjust path if needed */
}

.custom-container {
    padding-top: 0 !important;
}


.multi-color-text {
    background: linear-gradient(to right, #373A40	, #DC5F00	, #DAA06D	, #373A40	, #DC5F00	);
    padding-top: 0;
    -webkit-background-clip: text;
    color: transparent;
    font-size: 85px;
    font-family: 'LongGame', sans-serif;
    display: inline-block;
    text-align: center;
    transform: rotateY(45deg);
    margin-bottom: 0;
  }

@media screen and (max-width: 768px) {
    .multi-color-text {
        font-size: 65px;
    }
}

@media screen and (max-width: 480px) {
    .multi-color-text {
        font-size: 45px;
    }
}

@media screen and (max-width: 380px) {
    .multi-color-text {
        font-size: 35px;
    }
}
.responsive-text {
    font-size: 1.5rem; /* Default font size */
    color: #686D76;
    margin-top: 0;
}

@media screen and (max-width: 600px) {
    .responsive-text {
        font-size: 1.25rem; /* Adjust font size for small screens */
    }
}

@media screen and (max-width: 400px) {
    .responsive-text {
        font-size: 1rem; /* Adjust font size for extra small screens */
    }
}

@media screen and (max-width: 300px) {
    .responsive-text {
        font-size: 0.8rem; /* Adjust font size for extra small screens */
    }
}

.background {
    position: relative;
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    min-height: 100%; /* Ensures it takes full height on smaller screens */
}

.background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../resources/backg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2; /* Adjust the opacity of the background image */
    z-index: -1; /* Ensure the background is behind other content */
}

/* Media query to handle smaller screens */
@media (max-width: 768px) {
    .background {
        height: 100%; /* Ensures the background stretches in smaller viewports */
    }
    
    .background::before {
        background-size: cover;
        background-position: top; /* Adjust for better fit on mobile */
    }
}

@keyframes shine {
    0% {
        border-color: #EEEEEE;
    }
    50% {
        border-color: #686D76; /* Color at peak of shine */
    }
    100% {
        border-color: #373A40;
    }
}

@keyframes colorChange {
    0% {
        color: linear-gradient(to right, #373A40	, #DC5F00	, #DAA06D	, #373A40	, #DC5F00	);
    }
    50% {
        color: linear-gradient(to right, #DC5F00	, #373A40	, #DAA06D	, #DC5F00	, #373A40);
    }
    100% {
        color: linear-gradient(to right, #373A40	, #DC5F00	, #DAA06D	, #373A40	, #DC5F00	);
    }
  }
