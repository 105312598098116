.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin: 0 auto;
  width: max-content;
}

.sub-board {
  position: relative; /* Ensure overlay can be positioned on top */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  background: #373A40; /* Dark background for sub-boards */
  padding: 10px;
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.tile {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #EEEEEE; /* Light border */
  border-radius: 5px;
  background: #686D76; /* Tile background */
  cursor: pointer;
  font-size: 24px;
  transition: background 0.3s, transform 0.2s;
}

.tile.X {
  color: #DC5F00; /* Orange for X */
  font-weight: bold;
}

.tile.O {
  color: #EEEEEE; /* Light color for O */
}

.tile:hover {
  transform: scale(1.05);
}

.tile:active {
  background: #373A40;
}

.game-status {
  margin-top: 20px;
  font-size: 1.2em;
  color: #DC5F00; /* Status in orange */
}

@media (max-width: 600px) {
  .board {
    gap: 10px;
  }

  .sub-board {
    gap: 3px;
    padding: 5px;
  }

  .tile {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .board {
    gap: 8px;
  }

  .sub-board {
    gap: 2px;
    padding: 4px;
  }

  .tile {
    width: 25px;
    height: 25px;
    font-size: 18px;
  }

  .ultimate-tictactoe h1 {
    font-size: 1.5em;
  }

  .game-status {
    font-size: 1em;
  }
}

.board-winner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Prevent interaction */
}

.full-board {
  position: absolute; /* Ensure it overlays without affecting layout */
  width: 100%; /* Keep the same size as the sub-board */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Prevent interaction */
}

.winner-text {
  font-size: 3rem;
  color: white;
  font-weight: bold;
}
