.win-table-container {
    margin-top: 20px;
    text-align: center; /* Center the h2 */
}

.win-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 300px; /* Set a max-width to prevent overflowing on small screens */
    margin: 0 auto; /* Center the table */
}

.win-table th,
.win-table td {
    border: 1px solid #686D76;
    padding: 8px;
    text-align: center;
}

.win-table th {
    background-color: #f2f2f2;
}

.win-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.win-table tr:hover {
    background-color: #ddd;
}
