.BattleShip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    gap: 1px;
    width: 500px;
    height: 500px;
    border: 1px solid #686D76;
}

.cell {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #686D76;
    background-color: #EEEEEE;
}

.cell .ship {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #614129;
    border: 1px solid #614129;
}

.cell:hover {
    background-color: #e5e5e5;
}

.grid-label {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    color: #333;
}

.randomize-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3f4242;
    color: #DC5F00;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.randomize-button:hover {
    background-color: #373A40;
}

.cross-mark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 24px;
    font-weight: bold;
}

.cell.miss {
    pointer-events: none;
    background-color: #686D76;
}

.player-grid-container, .opponent-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

/* Media query for responsive layout change */
@media (max-width: 768px) {
    .BattleShip {
        flex-direction: column;
    }

    .grid {
        width: 90vw;
        height: 90vw;
    }
}
