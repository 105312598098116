.opponent-ships-table {
    width: 80%;
    border-collapse: collapse;
    margin: 20px auto;
    font-size: 0.9em;
    font-family: 'Arial', sans-serif;
    min-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    background-color: #f7e4d5;
}

.opponent-ships-table thead tr {
    background-color: #373A40;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}

.opponent-ships-table th,
.opponent-ships-table td {
    padding: 10px 12px;
}

.opponent-ships-table tbody tr {
    border-bottom: 1px solid #373A40;
}

.opponent-ships-table tbody tr:nth-of-type(even) {
    background-color: #f3e0c6;
}

.opponent-ships-table tbody tr:last-of-type {
    border-bottom: 2px solid #8b4513;
}

.opponent-ships-table tbody tr:hover {
    background-color: #e5c4a1;
}

.ship-name {
    font-size: 1em;
    font-weight: bold;
    color: #5a3e26;
}

.ship-status {
    display: flex;
    gap: 5px;
}

.ship-status .destroyed svg {
    color: #DC5F00; /* Red for destroyed */
}

.ship-status .undestroyed svg {
    color: #138b29; /* Brown for undestroyed */
}

.ship-status-text {
    font-size: 0.9em;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 5px;
    color: #ffffff;
}

.destroyed-text {
    background-color: #DC5F00;
}

.not-destroyed-text {
    background-color: #686D76;
}
