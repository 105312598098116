.App {
    text-align: center;
    margin-top: 2rem;
    overflow: hidden; /* Prevent scrollbars */
  }
  
  .graph-container {
    width: 100%;
    height: 100%; /* Fill the entire .App container */
  }

  .vis-network .edge {
    stroke-width: 5px; /* Adjust the width as needed */
  }